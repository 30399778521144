<template>
    <div class="enterprise">
        <div class="banner">
            <img src="../../assets/PC/duty_img_banner.png" alt="">
            <div class="text" :style="textLeft">
                <h2>连接责任与信任</h2>
                <p>微小善行助力公益，以科技让世界更美好</p>
            </div>
        </div>
        <div class="bannerH5">
            <h3>连接责任与信任</h3>
            <h5>微小善行助力公益，以科技让世界更美好</h5>
        </div>

        <div class="content">
            <div class="main">
                <div class="card" id="enterprise1" @click="details('one')">
                    <div class="text">
                        <div class="title">全球战疫  互译共读</div>
                        <p>特殊时期的特殊阅读，通过高品质儿童图书增进国际理解和友谊</p>
                    </div>
                </div>
                    <div id="enterprise2" style="    height: 60px;width: 100%;"></div>
                <div class="card"  @click="details('two')">
                    <div class="text">
                        <div class="title">县域城市  助力高考</div>
                        <p>高考伊始巡游出租车队伍义务接送考生，助力考生金榜题名</p>
                    </div>
                </div>
                <div class="cardH5" @click="details('one')">
                    <img src="../../assets/H5/con_cardH51.png" alt="">
                    <div class="title">全球战疫  互译共读</div>
                    <p>特殊时期的特殊阅读，通过高品质儿童图书增进国际理解和友谊</p>
                    <img src="../../assets/H5/duty_ic_door.png" class="iocn" alt="">
                </div>
                <div class="cardH5" @click="details('two')">
                    <img src="../../assets/H5/con_cardH52.png" alt="">
                    <div class="title">县域城市  助力高考</div>
                        <p>高考伊始巡游出租车队伍义务接送考生，助力考生金榜题名</p>
                    <img src="../../assets/H5/duty_ic_door.png" class="iocn" alt="">
                </div>
            </div>
        </div>

        <div class="services" id="enterprise3">
            <div class="title">践行承诺</div>
            <div class="main">
                <ul>
                    <li @click="service(1)">
                        <div class="li_bgc"></div>
                        <img src="../../assets/PC/services1.png" alt="">
                        <img src="../../assets/H5/services1.png" class="img" alt="">
                        <div class="herf">
                            知识产权
                        </div>
                    </li>
                    <li @click="service(2)">
                        <div class="li_bgc"></div>
                        <img src="../../assets/PC/services2.png" alt="">
                        <img src="../../assets/H5/services2.png" class="img" alt="">
                        <div class="herf">
                            用户隐私
                        </div>
                    </li>
                    <li @click="service(3)">
                        <div class="li_bgc"></div>
                        <img src="../../assets/PC/services3.png" alt="">
                        <img src="../../assets/H5/services3.png" class="img" alt="">
                        <div class="herf">
                            数据安全
                        </div>
                    </li>
                    <li @click="service(4)">
                        <div class="li_bgc"></div>
                        <img src="../../assets/PC/services4.png" alt="">
                        <img src="../../assets/H5/services4.png" class="img" alt="">
                        <div class="herf">
                            全程守护
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            textLeft:'left:0px',
            scrollTopNumber:[]
        }
    },
    watch: {
        "$route": "fetchDate",
    },
    mounted () {
        
        // 检测界面宽度设置left
        if(document.body.clientWidth>1270){
            this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
        }else{
            this.textLeft='left:20px' 
        }
        window.onresize = () => {
            return (() => {
                if(document.body.clientWidth>1270){
                    this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
                }else{
                    this.textLeft='left:20px'
                }
            })();
        };
        let this_=this;
         setTimeout(function() {
            if(this_.$route.query.herfIndex){
                this_.scrollTopNumber=[document.querySelector('#enterprise1').offsetTop-120,
                document.querySelector('#enterprise2').offsetTop-70,
                document.querySelector('#enterprise3').offsetTop-70]
                document.documentElement.scrollTop=this_.scrollTopNumber[this_.$route.query.herfIndex]
                window.scrollTo(0, this_.scrollTopNumber[this_.$route.query.herfIndex])
                // console.log(this_.scrollTopNumber[this_.$route.query.herfIndex])
            }
        }, 100)
    },
    methods: {
        fetchDate(){
            this.scrollTopNumber=[document.querySelector('#enterprise1').offsetTop-120,
            document.querySelector('#enterprise2').offsetTop-70,
            document.querySelector('#enterprise3').offsetTop-70]
            if(this.$route.query.herfIndex){
                document.documentElement.scrollTop=this.scrollTopNumber[this.$route.query.herfIndex]
                window.scrollTo(0, this.scrollTopNumber[this.$route.query.herfIndex])
                // console.log(this.scrollTopNumber[this.$route.query.herfIndex])
            }
            // console.log('enterprise')
        },
        details(type){
            this.$router.push({
                path:'/details',
                query:{
                    type:type
                }
            })
        },
        service(type){
            this.$router.push({
                path:'/service',
                query:{
                    herfIndex:type
                }
            })
        },

    }
}
</script>
<style lang="less" scoped>
    .enterprise{
        padding-top: 70px;
        .bannerH5{
            display: none;
        }
        .banner{
            position: relative;
            width: 100%;
            img{
                width: 100%;
                height: 476px;
                // float: left;
            }
                .text{
                    position: absolute;
                    left: 12%;
                    top: 50%;
                    transform: translateY(-50%);
                    h2{
                        font-size: 64px;
                        color: #fff;
                        font-weight: normal;
                    }
                    p{
                        margin-top: 30px;
                        color: #fff;
                        font-size: 24px;
                    }
                }
        }

        .content{
            padding-top:120px;
            .cardH5{
                display: none;
            }
            .card{
                display: block;
                cursor: pointer;
                position: relative;
                width: 100%;
                height: 400px;
                // margin-bottom: 60px;
                background:url(../../assets/PC/content1.png) no-repeat ;
                background-size: 100% 100%;
                .text{
                    position: absolute;
                    z-index: 1;
                    top: 78px;
                    left: 40px;
                    .title{
                        font-size: 36px;
                    }
                    p{
                        font-size: 16px;
                        margin-top: 20px;
                    }
                }
            }
            .card::after{
                content: '';
                position: absolute;
                top: 48px;
                left: -20px;
                transform: skewX(-10deg);
                width: 640px;
                height: 160px;
                background-color: rgba(255, 255, 255, 0.8);
            }
            .card:nth-child(3){
                background:url(../../assets/PC/content2.png) no-repeat ;
                background-size: 100% 100%;
            }
        }
        .services{
            padding-top: 120px;
            margin-bottom: 100px;
            .title{
                text-align: center;
                font-size: 36px;
                margin-bottom: 50px;
            }
            ul{
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                li{
                    cursor: pointer;
                    position: relative;
                    width: 49%;
                    margin-bottom: 24px;
                    .li_bgc{
                        position: absolute;
                        display: none;
                        background-color:rgba(0, 0, 0, 0.2);
                        width: 100%;
                        height: 100%;
                    }
                    .herf{
                        position: absolute;
                        bottom: 48px;
                        left: 48px;
                        font-size: 24px;
                        color: #fff;
                        border-bottom: 4px solid transparent;
                    }
                    img{
                        display: block;
                        width: 100%;
                    }
                    .img{
                        display: none;
                    }
                }
                li:hover .herf{
                    border-bottom: 4px solid #fff;
                }
                li:hover .li_bgc{
                    display: block;
                }
            }
        }
    }
    // 手机端样式
    #app.H5Class {
        .enterprise{
            width: 7.5rem;
            padding-top: 0.8rem;
            padding-bottom: 0.7rem;
            .banner{
                display: none;
            }
            #enterprise2{
                height: 0.01rem!important;
            }
            .bannerH5{
                overflow: hidden;
                width: 100%;
                display: block;
                height: 5.6rem;
                background:url(../../assets/H5/duty_img_banner.png) no-repeat;
                background-size: 100% 100%;
                h3{
                    margin-top: 1.2rem;
                    color: #fff;
                    font-size: 0.6rem;
                    font-weight: 400;
                    padding-left: 0.4rem;
                }
                h5{
                    margin-top: 0.3rem;
                    color: #fff;
                    padding-left: 0.4rem;
                    font-size: 0.24rem;
                    font-weight: 400;
                    width: 4.5rem;
                }
            }
            
            .content{
                padding-top: 0px;
            }
            .card{
                display: none;
            }
            .cardH5{
                display: block;
                position: relative;
                width: 6.7rem;
                margin: 0.6rem auto;
                background-color: #f8f8f8;
                height:6.4rem ;
                img{
                    width: 100%;
                    height: 3.2rem;
                }
                .title{
                    font-size: 0.34rem;
                    margin-top:0.5rem ;
                    padding-left: 0.4rem;
                }
                p{
                    font-size: 0.24rem;
                    padding: 0.3rem 0.4rem;
                }
                .iocn{
                    position: absolute;
                    width: 0.5rem;
                    height: 0.6rem;
                    right: 0.4rem;
                    bottom: 0.3rem;
                }
            }
            .services{
                width: 6.7rem;
                margin: 0.6rem auto; 
                margin-top: 1.2rem;
                padding-top: 0;
                .title{
                    font-size: 0.34rem;
                    margin-bottom: 0.4rem;
                }
                ul{
                    li{
                        width: 100%;
                        img{
                            display: none;
                            width: 100%;
                            height: 2.4rem;
                        }
                        .img{
                            display: block;
                        }
                        .herf{
                            bottom: 0.4rem;
                            left: 0.4rem;
                            font-size: 0.28rem;
                        }
                    }
                }
            }
        }
    }
</style>